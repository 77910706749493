<template>
  <div class="notice-container">
    <el-row class="header" type="flex">
      <el-col :span="18" class="search-option">
        <!-- <el-input v-model="keyword" class="search-input" placeholder></el-input>
        <el-button type="primary" @click="handleSearch">搜索</el-button>-->
      </el-col>
      <el-col :span="6" class="create-option">
        <el-button type="primary" @click="handleCreate">添加公告</el-button>
      </el-col>
    </el-row>
    <div class="content">
      <el-table :data="notices.items" border stripe>
        <el-table-column
          v-for="col in columns"
          :key="col.id"
          :prop="col.id"
          :label="col.label"
          :width="col.width"
          :formatter="col.formatter"
        ></el-table-column>
        <el-table-column label="Action" width="300" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="handleEdit(scope.row)"
              >编辑</el-button
            >
            <el-button
              v-if="+scope.row.status === 0 || +scope.row.status === -1"
              size="mini"
              type="success"
              @click="handleUp(scope.row)"
              >启用</el-button
            >
            <el-button
              v-if="+scope.row.status === 1"
              size="mini"
              type="warning"
              @click="handleDown(scope.row)"
              >停用</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import config from "@/configs/index";

const noticeApi = config.url + "/admin_wallet/api/notice";

export default {
  data() {
    return {
      keyword: "",
      notices: {},
      columns: [
        { id: "id", label: "ID", width: 80 },
        { id: "cn_title", label: "公告标题" },
        { id: "en_title", label: "英文标题" },
        { id: "cn_content", label: "公告内容" },
        { id: "en_content", label: "英文内容" },
        {
          id: "status",
          label: "状态",
          width: 80,
          formatter: (row) => {
            switch (+row.status) {
              case 0:
                return <el-tag>未发布</el-tag>;
              case 1:
                return <el-tag type="success">上架</el-tag>;
              case -1:
                return <el-tag type="warning">下架</el-tag>;
              default:
                return row.status;
            }
          },
        },
        { id: "created_at", label: "创建时间" },
        { id: "updated_at", label: "更新时间" },
      ],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const params = {
          all: 1,
        };
        const { data } = await axios.get(noticeApi, { params });
        if (+data.code === -1) {
          return;
        }
        const noticeList = data.data.data_list || [];
        if (noticeList) {
          this.notices = {
            items: noticeList,
            count: noticeList.length,
          };
        }
      } catch (error) {
        console.log(error);
      }
    },
    handleCreate() {
      this.$router.push(`/app/notice/create`);
    },
    handleEdit(data) {
      this.$router.push(`/app/notice/edit/${data.id}`);
    },
    async updateNotice(id, params) {
      try {
        const data = {
          ...params,
        };
        await axios.put(`${noticeApi}/${id}`, data);
        this.$message({ message: "操作成功", duration: 3000, type: "success" });
        this.fetchData();
      } catch (error) {
        console.log(error);
        this.$message({ message: "操作失败", duration: 3000, type: "fail" });
      }
    },
    handleUp(notice) {
      if (!notice.id) {
        return;
      }
      this.updateNotice(notice.id, { status: 1 });
    },
    handleDown(notice) {
      if (!notice.id) {
        return;
      }
      this.updateNotice(notice.id, { status: -1 });
    },
    handleDelete(id) {
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.delete(id);
      });
    },
    async delete(id) {
      try {
        const res = await axios.delete(`${noticeApi}/${id}`);
        if (res && res.data && +res.data.code === 0) {
          this.$message({ message: "成功", duration: 3000, type: "success" });
          this.fetchData();
        } else {
          this.$message({
            message: res.data.msg,
            duration: 3000,
            type: "fail",
          });
          this.fetchData();
        }
      } catch (error) {
        console.log(error);
        this.$message({ message: "操作失败", duration: 3000, type: "fail" });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.notice-container {
  margin: 0;

  .header {
    margin: 20px;
  }

  .search-input {
    width: 300px;
  }

  .create-option {
    display: flex;
    justify-content: flex-end;
  }

  .content {
    padding: 0 20px 20px;
  }
}
</style>
